const base = {
  Realtalk: "Realtalk",
  DRK: "© 2023 Deutsches Rotes Kreuz",

  // index page

  STARTINFOLABEL: "Start Frühjahr 2024",

  Introduction: [
    "Wenn keiner für dich da ist, sind wir es.",
    "Anonyme Chat-Beratung von Jugendlichen für Jugendliche",
    "Terminbuchung bei Schulsozialarbeitenden ",
  ],
  Anonym: ["Anonym", "Dein Peer-Chat ist 100% anonym."],
  ConfidentialConversations: [
    "Vertrauliche Gespräche",
    "Deine Privatsphäre und deine Informationen sind bei uns geschützt.",
  ],
  FreeOffer: ["Kostenloses Angebot", "Unser komplettes Angebot ist kostenlos."],
  Topics: "Weil jedes Thema ein Thema sein kann, sind wir für dich da.",

  // chatAdvice page
  Header: "Dein Thema. Dein Chat. Dein Support.",
  SubHeader:
    "Hallo! RealTalk ist eine Chat-Beratung von Jugendlichen für Jugendliche. Hier bekommst du Hilfe, Rat und Austausch zu dem, was dich bedrückt oder bewegt. Du bestimmst das Thema. Dein Peer schenkt dir Support.",
  Buttons: ["WhatsApp-Chat starten", "SMS-Chat starten"],
  OpenHoursBanner: [
    "Dienstag ist Peertag:",
    "Chatte mit uns jeden Dienstag von",
  ],
  StartTerm: [
    "Start im Oktober 2023:",
    "Wir arbeiten derzeit daran, dieses Angebot für dich fertigzustellen. Bleib dran!",
  ],
  Terms: [
    "Der Chat ist aktuell geschlossen",
    "Dienstags:",
    "16 - 20 Uhr",
    // " Wochenende & Feiertage:",
    // "8 - 22 Uhr",
  ],
  PeersHeader: "Zitate Peers:",
  PeersList: [
    {
      Peer: "Pascal, 16",
      Text: '"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diamnonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."',
    },
    {
      Peer: "Sabine, 17",
      Text: '"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diamnonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."',
    },
    {
      Peer: "Amelie, 18",
      Text: '"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diamnonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."',
    },
  ],
  JoinPeers: [
    "Du bist 16-20 Jahre alt und möchtest anderen helfen?",
    "Mach mit bei Realtalk und berate andere Jugendliche per Chat",
  ],
  LearnMoreBtn: "Mehr erfahren",
  StartInDRKLabelBold: "Start Frühling 2024:",
  StartInDRKLabel:
    "Wir arbeiten derzeit daran, dieses Angebot für dich fertigzustellen. Bleib dran!",

  // appointmentBooing page
  TermBooking: [
    "Buche einen Termin bei Sozialarbeitenden an deiner Schule",
    "Buche einen Beratungstermin bei deiner/m Schulsozialarbeitenden – vor Ort in der Schule oder per Video.",
  ],
  ButtonLabel: "Jetzt Termin finden",
  SecurityQuestion: ["Sicherheitsabfrage", "Lorem ipsum dolor?"],
  SchoolSection: [
    "Schule nicht gefunden?",
    "Du würdest gerne Termine bei deiner/deinem Sozialarbeitenden über RealTalk buchen, aber deine Schule ist nicht dabei?",
  ],
  SchoolSectionBtn: "Sag uns Bescheid",
  WriteSchoolZipLabel: "Gib die Postleitzahl deiner Schule ein",
  SelectSchoolLabel: "Schule auswählen",
  SelectClassLabel: "Klasse auswählen",
  ZipInputPlaceholder: "Postleitzahl eingeben",
  BetaVersionWarninigTextHighlighted: "Beta-Version: ",
  BetaVersionWarninigText:
    "Im Augenblick nehmen nur ausgewählte Schulen an der Terminbuchung teil",
  NoSchoolByZip: "In diesem PLZ-Bereich haben wir keine Schule gefunden.",

  //  Slider Section
  SliderHeaderLabel: "Wer sind die Sozialarbeitenden?",

  // employeeSelect page
  EmployeeSelectHeader: "Mitarbeiter:in auswählen",
  AllEmployeesBtn: "Alle Mitarbeiter:innen",
  EmployerSelectpage: {
    MoreInformation: "Mehr Informationen",
    BookAppointmentButton: "Termin buchen",
  },

  // FAQ page
  FAQPageHeader: ["FAQ", "Hier findest du Antworten auf die häufigsten Fragen"],
  ChatAdviseSection: "Chat-Beratung",
  ChatAdviseQuestions: [
    {
      Question: "Mit wem schreibe ich bei RealTalk?",
      Answer:
        "Bei uns unterstützen dich ausgebildete Peers, das sind Jugendliche zwischen 16  und 20 Jahren. Diese besondere Form der Beratung ermöglicht eine vertrauensvolle und gleichberechtigte Kommunikation zwischen euch. Unser Ziel ist es, dass du dich beim Chatten wohl, auf Augenhöhe und verstanden fühlst.",
    },
    {
      Question: "Wie können mir Jugendliche bei meinen Problemen helfen?",
      Answer:
        "Wir sind davon überzeugt, dass unsere Peers aufgrund ihres Alters und ihrer zusätzlichen Ausbildung die idealen Gesprächspartner für dich sind. So schaffen wir eine gleichberechtigte Kommunikation, bei der deine Ansprechpartner ihre eigenen Erfahrungen mit einbringen und deine Probleme besser verstehen können, weil sie sie vielleicht selbst schon erlebt haben. Sie können vielleicht Themen besser nachvollziehen, bei denen du dich von Erwachsenen nicht verstanden fühlst.",
    },
    {
      Question: "Welche Art der Unterstützung kann ich von RealTalk bekommen?",
      Answer:
        "Unsere Peer-Beratung soll dir einen unkomplizierten Austausch auf Augenhöhe bieten. Die Peers sind da, um dir zuzuhören. Wir wollen dich in schwierigen Situationen unterstützen und dir zur Seite stehen. Wir wissen aber auch, dass unsere Peers keine professionelle Hilfe wie z.B. durch Psychologen oder Schulsozialarbeitende ersetzen können.",
    },
    {
      Question: "Wie lange kann ich mit den Peers chatten?",
      Answer:
        "Ein Chat dauert maximal 60 Minuten. Danach wird der Chat automatisch beendet. Du kannst jederzeit einen neuen Chat starten. Es kann jedoch sein, dass du dann mit einem anderen Peer schreibst.",
    },
    {
      Question: "Was ist, wenn ich mich im Chat nicht wohlfühle?",
      Answer:
        "Kein Problem. Du kannst den Chat jederzeit beenden. Du kannst am Ende des Chats ein paar Fragen beantworten und hier Feedback geben, wenn du das möchtest. Außerdem kannst du auch ausführlicheres Feedback über das Kontaktformular schreiben. Du hast aber natürlich auch die Möglichkeit, im Gespräch zu sagen, dass es nicht passt und den Peer zu bitten, den Chat zu beenden, um einen neuen Chat mit einem anderen Peer zu starten.Wie kann ich RealTalk kontaktieren?",
    },
    {
      Question: "Wie kann ich RealTalk kontaktieren?",
      Answer: "Du kannst RealTalk über WhatsApp oder per SMS kontaktieren.",
    },
    {
      Question: "Wann kann ich RealTalk schreiben?",
      Answer:
        "Du kannst jeden Dienstag zwischen 16 und 20 Uhr einen Chat in Realtalk starten. Wenn du ein Problem hast, das nicht warten kann, wende dich bitte an deine Schulsozialarbeitenden oder an das Kinder- und Jugendtelefon unter 116111.",
    },
    {
      Question: "Mit welchen Themen kann ich mich an RealTalk wenden?",
      Answer:
        "Ganz egal, mit welchem Problem du kämpfst, unsere Peers sind für dich da. Sie hören dir zu und bieten dir Unterstützung bei Problemen wie Stress in der Schule, Mobbing, Liebeskummer oder Depressionen.",
    },
    {
      Question: "Was kostet Realtalk?",
      Answer:
        "Die Beratung bei RealTalk ist ein kostenloses Angebot für Kinder und Jugendliche in ganz Deutschland. Wir möchten dich jedoch darauf aufmerksam machen, dass je nach Netzanbieter und Vertragsdetails das Versenden von SMS oder die Nutzung von mobilen Daten Kosten verursachen kann. Bitte informiere dich über mögliche Kosten, bevor du RealTalk nutzt.",
    },
    {
      Question: "Ist RealTalk anonym?",
      Answer:
        "Du chattest mit unseren Peers komplett anonym. Weder dein Name, deine Telefonnummer, dein Profilbild noch dein Status werden an sie übermittelt. Auch die Jugendlichen, die dich beraten, bleiben anonym. In schwierigen Momenten können auch die Peers von einem Begleitteam unterstützt werden. In absoluten Notfällen kann das Begleitteam der Peers deine Telefonnummer aufdecken und diese an die Polizei weitergeben. Die Polizei kann dann deine Nummer orten, um dir vor Ort Hilfe zu leisten.",
    },
    {
      Question: "Warum nutzt ihr WhatsApp?",
      Answer:
        "WhatsApp ist das beliebteste Kommunikationstool für Kinder und Jugendliche. Es bietet dir eine vertraute Umgebung, die du wahrscheinlich täglich nutzt. Darüber hinaus ist WhatsApp für alle mit Internetzugang kostenlos.",
    },
    {
      Question: "Warum ist meine Nummer blockiert?",
      Answer:
        "Wenn deine Nummer blockiert ist, könnte das darauf hindeuten, dass du dich unseren Peers gegenüber unangemessen verhalten hast. Im Chat erwarten wir, dass du dich den anderen Personen gegenüber nicht beleidigend oder anstößig verhältst und die Privatsphäre unserer Peers respektierst. Sexuell übergriffiges Verhalten, Hate Speech, Beleidigungen und Beschimpfungen sind tabu. Bleibe bitte immer höflich und respektvoll im Chat, auch wenn du frustriert bist. Solltest du dich nicht an diese Regeln halten, kannst du von unseren Peers blockiert werden und den RealTalk Peer-Chat nicht mehr nutzen.",
    },
    {
      Question: "Was passiert mit meinen Daten?",
      Answer:
        "Deine Privatsphäre und deine Informationen sind bei uns geschützt. Wenn du genau wissen willst, wie deine Daten verarbeitet werden, kannst du dich dazu in unseren Datenschutzbestimmungen informieren.",
    },
    {
      Question: "Wann informiert RealTalk die Polizei?",
      Answer:
        "Wenn unsere Peers zusammen mit dem Begleitteam beurteilen, dass du dich in einer akuten, lebensbedrohlichen Situation befindest (zum Beispiel bei Suizidabsichten oder wenn du Gefahr durch andere Personen drohst), wird das Begleitteam, das die Peers in ihrer Arbeit unterstützt, die Polizei informieren. Diese kann dann mithilfe deiner Telefonnummer, die nur im Notfall und ausschließlich durch das Begleitteam offengelegt werden kann, deine Position orten, um dir direkt vor Ort Unterstützung zu bieten. Sollten unsere Peers und das Begleitteam feststellen, dass du eine andere Person in akute Gefahr bringst, sind wir verpflichtet, dies gemäß der gesetzlichen Meldepflicht des Strafgesetzbuches (§138 StGB) bei schweren Straftaten wie Mord zu melden. Bei weniger schweren Delikten wie Sachbeschädigung, Drogenkonsum oder Diebstahl besteht hingegen keine Meldepflicht.",
    },
  ],
  AppointmentAdviseSection: "Terminbuchung",
  AppointmentAdviseQuestions: [
    {
      Question: "Bei wem kann ich einen Termin buchen?",
      Answer: `Über "RealTalk" kannst du ausschließlich einen Termin bei Schulsozialarbeitenden an deiner Schule machen. Sollten deine Schulsozialarbeitenden noch nicht auf RealTalk sein, melde dich bei uns.`,
    },
    {
      Question: "Wie kann ich Termine bei den Schulsozialarbeitenden buchen?",
      Answer: `1. Klicke auf "Terminbuchung bei Schulsozialarbeitenden" auf der Startseite \n 2. Gib dann deine Postleitzahl ein \n 3. Wähle eine Person \n 4. Klicke auf "Termin buchen" \n 5. Du wirst direkt an den Kalender des/der Schulsozialarbeitenden weitergeleitet und kannst dort einen Termin wählen. \n 6. Nachdem du einen Termin gebucht hast, erhältst du via Mail eine Benachrichtigung `,
    },
    {
      Question:
        "Ich möchte gerne von einem anderen Schulsozialarbeitenden von einer anderen Schule beraten werden. Geht das?",
      Answer:
        "Das ist leider nicht möglich, du kannst du ausschließlich einen Termin bei Schulsozialarbeitenden an deiner Schule machen.",
    },
    {
      Question: "Wieso ist mein Schulsozialarbeitender nicht auf der Website?",
      Answer:
        "RealTalk ist derzeit noch in der Test-Phase und deswegen vielleicht noch nicht an jeder Stelle vollständig. Sollte deine Schule noch nicht auf RealTalk sein, kannst du dich unter Kontakt an uns wenden.",
    },
    {
      Question:
        "Wie und wo findet ein Termin bei den Schulsozialarbeitenden statt?",
      Answer:
        "Du kannst entweder einen Videocall vereinbaren oder dich mit den Schulsozialarbeitenden in der Schule treffen.",
    },
    {
      Question:
        "Zu welchen Uhrzeiten kann ich Termine bei den Schulsozialarbeitenden buchen?",
      Answer:
        'Wann und für wie lange ihr Termine buchen könnt, legen eure Schulzialarbeitenden selber in ihren Kalendern fest. Bitte sucht euch unter "Terminbuchung bei Schulsozialarbeitenden" für euch passende Termine aus.',
    },
    {
      Question: "Wie oft kann ich einen Termin buchen?",
      Answer:
        "Du kannst in der Regel einmal pro Woche einen Termin mit unseren Schulsozialarbeitenden vereinbaren.",
    },
    {
      Question: "Kann ich auch in den Ferien Termine buchen?",
      Answer: "Leider kannst du in den Ferien keine Termine buchen.",
    },
    {
      Question:
        "Kann ich einen Termin buchen, wenn ich keinen Zugang zu einem Videotool habe?",
      Answer:
        "Wenn du einen Video-Termin gebucht hast, musst du Microsoft Teams nicht herunterladen, um es zu nutzen. Du kannst natürlich auch einen Vor-Ort-Termin buchen, der in deiner Schule stattfindet.",
    },
    {
      Question: "Warum erfolgt die Terminbuchung nicht annoym?",
      Answer:
        "Leider ist eine anonyme Beratung durch Schulsozialarbeitende nicht möglich, auch wenn sämtliche Gespräche natürlich zu 100 % vertraulich sind. Wenn du gerne ganz anonym beraten werden möchtest, nutze doch unsere Peer-to-Peer-Beratung. ",
    },
  ],
  FAQMoreQuestions: "Du hast weitere Fragen oder brauchst Hilfe?",
  ContactUsBtn: "Nimm Kontakt mit uns auf!",

  // AboutUs page
  AboutUsSection: ["Über uns", ""],

  // Participate page
  ParticipateSectionHeader: [
    "Mitmachen",
    "RealTalk für dich oder deine Schule",
    "Für Mitarbeitende",
    "Für Jugendliche",
  ],
  TeenagersSectionContent:
    "Du möchtest Teil unseres Peer-Programms werden und anderen per Chat helfen?",
  SocialWorkersContent:
    "Du arbeitest an einer Schule und möchtest eine Online-Terminbuchung ermöglichen?",

  ParticipateMoreQuestion: " Mehr erfahren",

  // BecamePeer page
  BecamePeerSectionHeader: [
    "RealTalk – Deine Chance, anderen Jugendlichen zu helfen",
    "Möchtest du anderen Jugendlichen bei ihren Sorgen und Problemen beistehen? Bist du zwischen 16 und 20 Jahren alt? Dann suchen wir Dich als Peer für den RealTalk Chat!",
  ],

  // BecamePeer page
  BecameExpertSectionHeader: [
    "RealTalk zur Unterstützung von Mitarbeitenden und Eltern",
    "Vereinfache die Kommunikation und Terminbuchung für Mitarbeitende, Jugendliche und Eltern.",
  ],

  // SchoolsInformation page
  SchoolsInformationHeader: ["Information für Schulen", "Lorem ipsum dolor"],

  // Contact page
  ContactPageHeader: ["Kontakt", "Melde dich bei uns!"],

  // OtherAdvice page
  OtherAdviceHeader: ["Andere Beratungsangebote", "Lorem ipsum dolor"],
  OtherAdviseLalbel: "Überschrift",
  OtherAdviceNumber: [
    "Nummer gegen Kummer",
    "Lorem ipsum dolor",
    "116 111",
    "nummergegenkummer.de",
  ],
  OtherAdviceChildren: [
    "Caritas Beratung für Kinder und Jugendliche",
    "Lorem ipsum dolor",
    "Webseite",
  ],
  OtherAdviceConsultation: [
    "bke Onlineberatung",
    "Lorem ipsum dolor",
    "jugend.bke-beratung.de",
  ],
  DisabledContactButtonMSG:
    "Zum Abschicken des Kontaktformulars musst du alle Felder ausgefüllt haben.",

  // shared components

  // drawerMenu
  Menu: [
    "Chat-Beratung",
    "Terminbuchung",
    "Über uns",
    "FAQ",
    "Mitmachen",
    // "Andere Beratungsangebote", TODO: AndereBeratungen
    "Kontakt",
  ],
  DesktopMenu: [
    "Chat-Beratung",
    "Terminbuchung",
    "FAQ",
    "Über uns",
    "Mitmachen",
  ],
  SubMenu: [
    "Nutzungsbedingungen",
    "Datenschutz",
    "Erklärung zur Barrierefreiheit",
    "Impressum",
  ],

  // QuestionSection
  FAQHeader: "FAQs",
  FAQQuestions: {
    HomePage: [
      {
        Question: "Mit welchen Themen kann ich mich an RealTalk wenden?",
        Answer:
          "Ganz egal, mit welchem Problem du kämpfst, unsere Peers sind für dich da. Sie hören dir zu und bieten dir Unterstützung bei Problemen wie Stress in der Schule, Mobbing, Liebeskummer oder Depressionen.",
      },
      {
        Question: "Kostet Realtalk etwas?",
        Answer:
          "Die Beratung bei RealTalk ist ein kostenloses Angebot für Kinder und Jugendliche in ganz Deutschland. Wir möchten dich jedoch darauf aufmerksam machen, dass je nach Netzanbieter und Vertragsdetails das Versenden von SMS oder die Nutzung von mobilen Daten Kosten verursachen kann. Bitte informiere dich über mögliche Kosten, bevor du RealTalk nutzt.",
      },
      {
        Question:
          "Wie können mir andere Jugendliche bei meinen Problemen helfen?",
        Answer:
          " Wir sind davon überzeugt, dass unsere Peers aufgrund ihres Alters und ihrer zusätzlichen Ausbildung die idealen Gesprächspartner für dich sind. So schaffen wir eine gleichberechtigte Kommunikation, bei der deine Ansprechpartner ihre eigenen Erfahrungen mit einbringen und deine Probleme besser verstehen können, weil sie sie vielleicht selbst schon erlebt haben. Sie können vielleicht Themen besser nachvollziehen, bei denen du dich von Erwachsenen nicht verstanden fühlst.",
      },
    ],
    ChatAdvice: [
      {
        Question: "Mit wem schreibe ich bei RealTalk?",
        Answer:
          "Bei uns unterstützen dich ausgebildete Peers, das sind Jugendliche zwischen 16  und 20 Jahren. Diese besondere Form der Beratung ermöglicht eine vertrauensvolle und gleichberechtigte Kommunikation zwischen euch. Unser Ziel ist es, dass du dich beim Chatten wohl, auf Augenhöhe und verstanden fühlst.",
      },
      {
        Question:
          "Wie können mir andere Jugendliche bei meinen Problem helfen?",
        Answer:
          "Wir sind davon überzeugt, dass unsere Peers aufgrund ihrs Alters und ihrer zusätzlichen Ausbildung die idealen Gesprächspartner für dich sind. So schaffen wir eine gleichberechtigte Kommunkation, bei der deine Ansprechpartner ihre eigenen Erfahrungen mit einbringen und deine Probleme besser verstehen können, weil sie sie vielleicht selbst schon erlebt haben. Sie können vielleicht Themen besser nachvollziehen, bei denen du dich von Erwachsenen nicht verstanden fühlst.",
      },
      {
        Question:
          "Welche Art der Unterstützung kann ich von RealTalk bekommen?",
        Answer:
          "Unsere Peer-Beratung soll dir einen unkomplizierten Austausch auf Augenhöhe bieten. Die Peers sind da, um dir zuzuhören. Wir wollen dich in schwierigen Situationen unterstützen und dir zur Seite stehen. Wir wissen aber auch, dass unsere Peers keine professionelle Hilfe wie z.B. durch Psychologen oder Schulsozialarbeitende ersetzen können. ",
      },
    ],
    AppointmentBookings: [
      {
        Question: "Bei wem kann ich Termine buchen?",
        Answer:
          'Über "RealTalk" kannst du aussschließlich einen Termin bei Schulsozialarbeitenden an deiner Schule machen. Sollten dein Schulsozialarbeitenden noch nicht auf RealTalk sein, melde dich bei uns.',
      },
      {
        Question: "Wie kann ich Termine bei den Schulsozialarbeitenden buchen?",
        Answer:
          '1. Klicke auf "Terminbuchung bei Schulsozialarbeitenden" auf der Startseite  \n2. Gib dann deine Postleitzahl ein \n3. Wähle eine Person  \n4. Klicke auf "Termin buchen" \n5. Du wirst direkt an den Kalender des/der Schulsozialarbeitenden weitergeleitet und kannst dort einen Termin wählen. \n6. Nachdem du einen Termin gebucht hast, erhältst du via Mail eine Benachrichtigung.',
      },
      {
        Question:
          "Wie und wo findet ein Termin bei den Schulsozialarbeitenden statt?",
        Answer:
          "Du kannst entweder einen Videocall vereinbaren oder dich mit den Schulsozialarbeitenden in der Schule treffen.",
      },
    ],
  },
  MoreQuestions: "Weitere Fragen & Antworten",

  // ParticipateContactUs
  ParticipateContactUs: {
    Headline: "Haben wir dich überzeugt?",
    SubText: "Super! Eine Anmeldung dauert nur 5 Minuten.",
    FormLinkText: "Hier zur Anmeldung",
    AdditionalQuestions: "Bei weiteren Fragen melde dich hier",
    ContactFormText: "Kontaktformular",
  },

  // HaveQuestions
  HaveQuestions: {
    Headline: "Hast Du Fragen?",

    SubText: "Dann schreib uns! Wir helfen dir gerne.",
    FormLinkText: "Zum Kontaktformular",
  },

  // ParticipateContactUs
  ParticipateExpertsContactUs: {
    Button: "Kontakt aufnehmen",
  },

  // Contact form section
  ContactFormLabel: " Ich bin*",
  ThemaInputLabel: "Thema*",
  ThemaItemOne: "Thema auswählen",
  ThemaInputTwo: "Intresseanmeldung",
  ThemaInputThree: "Technische Frage",
  ThemaInputFour: "Sonstige",
  YourNameInputLabel: "Deine Name",
  OrganizationIputLabel: "Schule / Organisation",
  YoursMessageInputLabel: "Deine Nachricht*",
  YoursEmailInputLabel: "Deine E-Mailadresse*",
  SendMessageButtonText: "Nachricht absenden",
  ContactForm: {
    DefaultSubject: "Kontakt via Kontaktformular",
  },

  //About Us Content Section
  AboutUs: [
    {
      Headline: "Was ist RealTalk?",
      Content:
        "RealTalk ist eine bundesweite Chat-Beratung von Jugendlichen für Jugendliche. Bei RealTalk helfen ehrenamtliche, qualifizierte Peers aus ganz Deutschland zwischen 16 und 20 Jahren Gleichaltrigen bei Stress in der Schule, Mobbing, Liebeskummer, Depressionen und anderen Problemlagen. RealTalk ist kostenlos, anonym und datenschutzkonform.",
    },
    {
      Headline: "Wer wir sind?",
      Content:
        "Das Deutsche Rote Kreuz rettet Menschen, hilft in Notlagen, bietet Menschen eine Gemeinschaft, steht den Armen und Bedürftigen bei und wacht über das humanitäre Völkerrecht - in Deutschland und weltweit. Das DRK engagiert sich in verschiedenen Bereichen wie der Gesundheitsversorgung, dem Katastrophenschutz und der sozialen Unterstützung, einschließlich der Sozialarbeit an Schulen oder Jugendhilfeeinrichtungen. Du willst mehr über uns erfahren? Hier erfährst du mehr: <0>https://www.drk.de/das-drk/</0>",
    },
  ],

  // Beacome Expert Content Section
  BecomeExpertContentSection: {
    Header: "Du möchtest RealTalk an deiner Schule ermöglichen?",
    Content:
      "RealTalk bringt viele Vorteile, auch für dich als Sozialarbeitende, Lehrkraft oder Elternteil:",
    ContentList: [
      [
        "Vereinfachte Terminbuchung:",
        "Das RealTalk Terminbuchungsystem ermöglicht die Online-Terminbuchung bei Sozialarbeitenden",
      ],
      ["Netzwerk erweitern:", "Kontakte mit andere Sozialarbeitenden knüpfen"],
      [
        "Ergänzender Support:",
        "Leicht zugängliche Unterstützung für die Jugendlichen durch den Peer-Chat",
      ],
    ],
  },

  // BeacomePeer Content Section
  BecomePeerPage: {
    Content: [
      {
        text: "Sei bei der nächsten Peer-Ausbildung in Berlin dabei!",
        type: "headline",
      },
      { type: "emptyline" },
      {
        text: "<b>Vom 12. - 14. September 2025</b> findet die nächste Peer-Ausbildung statt. Triff andere Jugendliche, lerne alles rund um die Onlineberatung, und vor allem – hab ganz viel Spaß!",
        type: "text",
      },
      {
        text: " Reise, Übernachtung und Verpflegung sind für dich <b>kostenlos.</b> Sichere dir jetzt deinen Platz!",
        type: "text",
      },
    ],
    FormLinkText: "Hier geht's zur Anmeldung",
    BadgeText: "Am 12.09.2025. geht es los!",
  },

  BecomePeerPartnerContentSection: {
    Header: "Wer kann Peer bei RealTalk werden?",
    Content:
      "RealTalk bringt viele Vorteile, auch für dich als Sozialarbeitende, Lehrkraft oder Elternteil:",
    ContentList: [
      "<b>Du bist zwischen 16 und 20 Jahren alt,</b> wohnst in Deutschland und kannst sicher deutsch schreiben und sprechen.",
      "Du hast einen <b>Laptop oder Computer mit Internetzugang,</b> mit dem du dich von zu Hause in das RealTalk-System einloggen kannst.",
      "<b>Du hilfst gerne anderen</b> und berätst Jugendliche bei Problemen und Sorgen, ob groß oder klein.",
    ],
  },

  WhyBecomePeerPartnerContentSection: {
    Header: "Warum RealTalk Peer werden?",
    Content:
      "RealTalk bringt viele Vorteile, auch für dich als Sozialarbeitende, Lehrkraft oder Elternteil:",
    ContentList: [
      [
        "/assets/imageLaptop.png",
        "Ehrenamt, ohne deine Couch zu verlassen",
        "Alles, was du brauchst, ist dein Computer oder Laptop und Internet.",
      ],
      [
        "/assets/booksIcon.png",
        "Spannende Lerneinheiten & Weiterbildungsmöglichkeiten",
        "Bei uns lernst du alles über Mental Health und Chatberatung. Außerdem bieten wir Weiterbildungen zu Themen wie LGBTQIA+ und Mobbing an.",
      ],
      [
        "/assets/medaileIcon.png",
        "Ein Zertifikat für deinen Lebenslauf",
        "Nach der kostenlosen Ausbildung und 12 Einsätzen erhältst du ein Zertifikat.",
      ],
      [
        "assets/handsIcon.png",
        "Unterstützung und Begleitung",
        "Erfahrene Psychologinnen, Pädagogen und Beratende unterstützen dich in deinem Ehrenamt.",
      ],
      [
        "/assets/checkIcon.png",
        "Die Ausbildung ist komplett kostenlos",
        "Die Kosten für Anfahrt, Übernachtung und Verpflegung werden vom Deutschen Roten Kreuz übernommen.",
      ],
      [
        "assets/HeartIcon1.png",
        "Neue Freunde kennenlernen",
        "Zweimal im Jahr nimmst du an coolen Veranstaltungen teil und triffst andere Peers.",
      ],
    ],
  },
  BecomePeerContentSection: [
    {
      Header: "Wir suchen dich!",
      Content:
        "Bist du zwischen 16 und 20 Jahre alt? Möchtest du anderen Jugendlichen bei ihren Sorgen und Problemem helfen? Möchtest du dich mit anderen Jugendlichen in deinem Altern online und offline vernetzen? Dann werde Peer bei RealTalk.",
    },
    {
      Header: "Wir unterstützen dich!",
      Content:
        "Um dich bei der Aufgabe als Peer zu unterstützen, ermöglichen wir dir einen kostenlose Ausbildung zur Peer-Beratung. Am Ende der Ausbildugn erhälst du ein Zertifikat von uns. Online und offline wirst du viele andere engagierte Peers treffen. Professionelle Ansprechpersonen unterstützen dich außerdem während der Beratung.",
    },
    {
      Header: "Sei dabei:",
      Content:
        "Die erste Ausbildung zur Peerberatung findet vom 24.11. - 26.11. in Potsdam statt. Die Ausbildung, inklusive Übernachtung und Verplegung ist kostenlos für dich!",
    },
  ],

  // Header Section
  HeaderSectionSchoolInputLabel: "Schule auswählen",
  HeaderSectionSchoolOne: "Carl von Ozzietzky-Schule",
  HeaderSectionSchoolTwo: "School2",
  HeaderSectionSchoolThree: "School3",
  SelectScoolLabel: "Schulklasse auswählen",
  ItemOne: "1",
  ItemTwo: "2",
  ItemThree: "3",
  SecurityQuestionLabel: "Antwort eingeben",
  SelectSecurityCodeLabel: "test question",
  TestCodeItem: "Sicherheitscode eingeben",

  // Impressum

  ImpressumTitle: "Impressum",
  LawInfoTitle: "Angaben gemäß § 5 TMG",
  AdressCompanyTitle: "Deutsches Rotes Kreuz e.V. DRK-Generalsekretariat",
  AdressStreet: "Carstennstraße 58",
  AdressCity: "12205 Berlin",
  CompanyRegister: "Vereinsregister: 95 VR 590 B",
  LawRegister: "Registergericht: Amtsgericht Berlin-Charlottenburg",
  RepresentedBy: "Vertreten durch:",
  RepresentedByContent:
    "Das Deutsche Rote Kreuz e.V. wird gesetzlich vertreten durch Christian Reuter, Generalsekretär und Vorsitzender des Vorstands.",
  ContactLabel: "Kontakt",
  MailAdress: "realtalk@drk.de",
  ValueAddedTaxLabel: "Umsatzsteuer-ID",
  ValueAddedTaxContent:
    "Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: 27/027/36500",
  DisputeResolutionLabel: "EU-Streitschlichtung",
  DisputeResolutionContent:
    "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:",
  DisputeResolutionLink: "https://ec.europa.eu/consumers/odr.",
  DisputeResolutionAddText:
    "Unsere E-Mail-Adresse finden Sie oben im Impressum.",
  ConsumerDisputeResolutionLabel:
    "Verbraucherstreitbeilegung /Universalschlichtungsstelle",
  ConsumerDisputeResolutionContent:
    "Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zuständig ist die Universalschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein",
  ConsumerDisputeResolutionLink: "https://www.verbraucher-schlichter.de/",
  LiabilityForContentsLabel: "Haftung für Inhalte",
  LiabilityForContentsContent:
    "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.",
  LiabilityForContentsContentPt2:
    "Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.",
  LiabilityForLinksLabel: "Haftung für Links",
  LiabilityForLinksContent:
    "Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.",
  LiabilityForLinksContentPt2:
    "Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
  CopyrightLabel: "Urheberrecht",
  CopyrightContent:
    "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.",
  CopyrightContentPt2:
    "Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.",
  SourceReferenceForPhotosUsedLabel: "Quellennachweis für verwendete Fotos",

  // Terms of Use

  TermsOfUse: {
    Title: "RealTalk Nutzungsbedingungen",
    Content: [
      {
        Content: [
          'Wir, des Deutsches Rotes Kreuz e.V. in Berlin, haben eine Online-Plattform namens RealTalk für euch geschaffen. Hier könnt ihr anonym mit ehrenamtlichen Berater*innen eures Alters (auch "Peers" genannt) sprechen.',
          "Der Deutsches Rotes Kreuz e.V., Generalsekretariat, Carstennstraße 58, 12205 Berlin (nachfolgend: „wir“ oder „uns“) stellt eine Online-Plattform (nachfolgend „RealTalk“ genannt) zur Verfügung, die es den Nutzerinnen und Nutzern (nachfolgend: „Jugendliche“, „ihr“ oder „euch“) ermöglicht, anonym mit gleichaltrigen ehrenamtlichen Berater*innen (nachfolgend „Peers“) in Kontakt zu treten.",
          "Des Weiteren bietet die Plattform einen direkten Verweis zum Beratungsangeboten von Schulsozialarbeiter*innen ausgewählter Deutsches Rotes Kreuz (DRK) Kreisverbände. Die Kommunikation mit Schulsozialarbeiter*innen und die Vereinbarung von Terminen mit diesen kann über Verlinkungen zu anderen Dienstleistern erfolgen. Die Bereitstellung dieser externen Plattformen und Dienste ist nicht Gegenstand unserer Leistung.",
          "Diese Nutzungsbedingungen gelten für die Nutzung von RealTalk. Ergänzend hierzu regelt die Datenschutzerklärung eure und unsere Rechte und Pflichten im Zusammenhang mit den einschlägigen Datenschutzgesetzen, insbesondere im Rahmen der Verarbeitung von Gesundheitsdaten.",
        ],
      },
      {
        Header: "1. Leistungsbeschreibung",
        Content: [
          "RealTalk richtet sich an alle interessierten Jugendlichen. RealTalk und die Beratung über RealTalk sind für Jugendlichen kostenlos, erfordern keine Registrierung und können anonym erfolgen. Die Beratung erfolgt über SMS oder WhatsApp. Die Bereitstellung dieser Dienste ist nicht Gegenstand unserer Leistung und muss durch euch auf eigene Kosten besorgt werden.",
          "Die digitale Peer-Beratung richtet sich an Jugendliche im Alter von 16 bis 20 Jahren und bietet Unterstützung in verschiedenen problematischen Lebenslagen wie beispielsweise Ängsten, schulischen Belastungen oder Mobbing.",
          "Eine qualifizierte Beratung durch die Peers fördern wir durch gezielte Schulungen und Verpflichtung der Peers, sowie durch regelmäßige Supervision ihrer Beratungstätigkeit, um die Einhaltung unserer Sicherheitsstandards und Qualitätsrichtlinien sicherzustellen.",
        ],
      },
      {
        Header: "2. Regelungen zu Beratungsleistungen",
        Content: [
          "Die RealTalk Beratung erfolgt in Form von Text. Die Beratung der Jugendlichen durch die Peers dient der niedrigschwelligen Erstansprache und findet im Rahmen einer unmittelbaren Beziehung zwischen Jugendlichen und uns statt. Es gelten die nachfolgenden Grundsätze:",
          "Themen, die im Rahmen der Online-Beratung behandelt werden können, sind beispielsweise allgemeines Wohlbefinden, Umgang mit Stress, soziale Kontakte, einfache psychosoziale Fragen und allgemeiner Erfahrungsaustausch.",
          "Die RealTalk Beratung kann keine professionelle Therapie, medizinische oder rechtliche Beratung ersetzen. Sie soll lediglich allgemeine Anhaltspunkte für die Lösung einer Problemsituation bieten und kann einfache Empfehlungen zum weiteren Vorgehen aussprechen.",
          "Die Beratung erfolgt durch ehrenamtliche Peers, die eine mehrwöchige Weiterbildung von uns erhalten . Sie handeln nach bestem Wissen und Gewissen und können, je nach vorliegenden Informationen, einfache Empfehlungen für das weitere Handeln geben.",
          "Für die Verarbeitung der personenbezogenen Daten der Jugendlichen gelten unsere Datenschutzhinweise.",
        ],
      },
      {
        Header: "3. Rechte und Pflichten der Jugendlichen",
        Content: [
          "Eigengebrauch: Ihr dürft die Dienste von RealTalk nur für eure eigenen privaten Zwecke nutzen, sofern nicht ausdrücklich andere Nutzungszwecke mit uns abgestimmt wurden.",
          "Urheberrechtlicher Schutz: Die Dienste oder Teile der Dienste können urheberrechtlich geschützt sein. Ihr erhaltet ein einfaches, nicht ausschließliches, nicht übertragbares und widerrufliches Recht zur persönlichen Nutzung der von uns bereitgestellten Dienste. Ein einfaches Recht bedeutet, dass ihr die Dienste nutzen könnt, ohne dass euch Eigentumsrechte übertragen werden. Dieses Recht ist nicht exklusiv, was bedeutet, dass auch andere Jugendlichen dasselbe Recht haben. Es ist nicht übertragbar, d.h. ihr dürft es nicht an Dritte weitergeben. Zudem kann dieses Recht von uns jederzeit widerrufen werden.",
          "Einschränkungen: Es ist euch nicht gestattet, Inhalte einzustellen oder zu verbreiten, die gegen geltendes Recht verstoßen. Dies umfasst unter anderem Inhalte, die rechtswidrig, irreführend, bösartig, diskriminierend, pornografisch, bedrohlich, beleidigend, obszön, diffamierend, ethisch anstößig, gewaltverherrlichend oder anderweitig unangemessen sind. Zudem dürft ihr keine Inhalte verbreiten, die Viren, Würmer, Trojaner oder andere schädliche Software enthalten.",
          "Schutzrechte: Das Entfernen oder Unkenntlichmachen von Urheberrechtsvermerken, Marken oder anderen Schutzrechten in den Diensten von RealTalk ist untersagt.",
          "Übertragung von Rechten und Pflichten: Ihr dürft Rechte oder Pflichten aus diesen Bedingungen nicht ohne unsere vorherige schriftliche Zustimmung an Dritte übertragen oder abtreten.",
        ],
      },
      {
        Header: "4. Änderung dieser Bedingungen",
        Content: [
          'Allgemeines: Bitte beachten Sie, dass wir die Nutzungsbedingungen und Datenschutzhinweise von "RealTalk" jederzeit ändern können. Da für die Nutzung von "RealTalk" keine Registrierung erforderlich ist und wir daher keine Kontaktdaten haben, können wir Sie nicht persönlich über solche Änderungen informieren.',
          'Änderungsmitteilung: Aktualisierungen oder Änderungen unserer Nutzungsbedingungen und Datenschutzhinweise werden prominent auf der "RealTalk"-Plattform veröffentlicht. Wir empfehlen daher, diese Bereiche regelmäßig zu besuchen, um sich über aktuelle Bedingungen und Richtlinien zu informieren.',
          'Zustimmung: Ihre fortgesetzte Nutzung der "RealTalk"-Plattform nach einer Änderung der Nutzungsbedingungen oder der Datenverarbeitungsrichtlinien gilt als Ihre Zustimmung zu diesen Änderungen.',
          "Datenverarbeitung: Für die Verarbeitung von Altdaten, die vor der Änderung der Nutzungsbedingungen oder Datenschutzhinweise gesammelt wurden, werden wir, soweit gesetzlich erforderlich, eine gesonderte Zustimmung einholen. Da wir jedoch in der Regel keine Kontaktdaten haben, wird diese Zustimmung im Rahmen einer erneuten Nutzung der Plattform eingeholt.",
        ],
      },
      {
        Header: "5. Verfügbarkeit der Plattform",
        Content: [
          "Die Plattform besteht aus komplexen Hard- und Softwarekomponenten, deren einzelne Bestandteile ständig miteinander interagieren und die gleichzeitig einer ständigen Anpassung durch Weiterentwicklungen, veränderte gesetzliche Anforderungen oder Sicherheitsupdates unterliegen. Wir werden sämtliche Anpassungen an den Hard- und Softwarekomponenten im Rahmen der bestehenden technischen und betrieblichen Möglichkeiten sorgfältig vornehmen, können aber eine ununterbrochene Verfügbarkeit der Plattform nicht zusichern. Wir sichern eine Verfügbarkeit der Plattform von 95% im Jahresmittel zu.",
          "Sofern eine Nichtverfügbarkeit der Plattform auf Vorsatz oder grober Fahrlässigkeit von uns oder eines Erfüllungsgehilfen von uns beruht, können wir uns nicht auf eine Einhaltung der Verfügbarkeitszusage nach der Leistungsbeschreibung (Ziffer 1) berufen.",
          "Wir sind für eine Unterschreitung der Verfügbarkeitszusage nicht verantwortlich, sofern die Nichterreichbarkeit auf höhere Gewalt, auf technische Störungen bei Dritten, die nicht Erfüllungsgehilfen von uns sind, oder auf einem rechtswidrigen Angriff auf uns oder einen von uns beauftragten Dienstleister beruhen.",
        ],
      },
      {
        Header: "6. Datenschutz",
        Content: [
          "Wir verpflichten uns zur Einhaltung des Datenschutzes. Für die Nutzung der Plattform gelten unsere Datenschutzhinweise. Für die Beratung durch die Schulsozialarbeiter*innen gelten Datenschutzhinweise der DRK-Kreisverbände, von der ihr Beratungsleistungen erhaltet.",
        ],
      },
      {
        Header: "7. Ausnahmen zur Datenweitergabe",
        Content: [
          "Um den Schutz der Ratsuchenden, der Allgemeinheit und die Integrität der Plattform zu gewährleisten, definiert RealTalk klare Richtlinien zur Datenweitergabe, die in Ausnahmefällen Anwendung finden. Diese Richtlinien sind Teil unserer vertraglichen Verpflichtungen gegenüber den Nutzern und setzen deren Verständnis und Einhaltung voraus:",
          "7.1 Verpflichtungen der Mitarbeitenden und der Peers:",
          "Schweigepflicht und Datenschutz: Alle Mitarbeitenden und Peers von RealTalk sind zur strikten Einhaltung der beruflichen Schweigepflicht gemäß §203 StGB verpflichtet. Die unbefugte Offenbarung von Geheimnissen unterliegt strengen Sanktionen.",
          "Handlungsanweisungen bei Gefährdung: RealTalk stellt spezifische Protokolle bereit, nach denen Mitarbeitende und Peers handeln müssen, wenn sie auf Informationen stoßen, die eine unmittelbare Gefahr für das Leben oder die Gesundheit der Ratsuchenden darstellen (z.B. Suizidandrohungen). Diese Anweisungen umfassen Schritte zur Meldung und Intervention im Einklang mit §323c StGB.",
          "7.2 Meldung von Straftaten:",
          "Anzeigepflicht bei schweren Straftaten: Bei glaubhaften Hinweisen auf schwere Straftaten wie Mord oder Terrorismus sind Mitarbeitende und Peers gesetzlich verpflichtet, diese den zuständigen Behörden zu melden (§138 StGB).",
          "Notstandshandlungen: In weniger schweren Fällen können und müssen Mitarbeitende und Peers handeln, wenn eine konkrete, gegenwärtige Gefahr besteht und keine milderen Mittel zur Verfügung stehen (§34 StGB). Diese Handlungen müssen angemessen und gerechtfertigt sein, um die Gefahr abzuwenden.",
          "7.3 Kindesschutz:",
          "Meldepflicht bei Kindeswohlgefährdung: Die Verantwortlichen müssen aktiv werden, wenn sie auf Umstände stoßen, die eine ernsthafte und unmittelbare Gefährdung des Kindeswohls anzeigen. Es gelten spezielle Meldeverfahren, die sowohl die gesetzlichen Vorschriften als auch die Richtlinien von RealTalk beachten.",
        ],
      },
      {
        Header: "8. Haftung ",
        Content: [
          "8.1 Haftung des Deutschen Roten Kreuzes",
          "Wir haften auf Schadenersatz wegen Verletzung vertraglicher oder außervertraglicher Pflichten nur bei Vorsatz oder grober Fahrlässigkeit sowie für garantierte Beschaffenheitsmerkmale, wegen der Verletzung des Lebens, des Körpers oder der Gesundheit oder nach dem Produkthaftungsgesetz.",
          "8.2 Haftung der Peers",
          "Die Peers, als ehrenamtliche Berater, sind Mitglieder oder freiwillige Helfer in Kreisverbänden des Deutschen Roten Kreuzes und handeln nach den Anweisungen des jeweiligen Kreisverbandes. Sie haften nicht für leicht fahrlässig verursachte Schäden. In Fällen von Vorsatz oder grober Fahrlässigkeit sowie für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, übernimmt das zuständige Kreisverband des Deutschen Roten Kreuzes die Haftung im Rahmen der gesetzlichen Bestimmungen. Die Peers sind verpflichtet, die Richtlinien und Anweisungen ihres jeweiligen Kreisverbands strikt zu befolgen.",
          "8.3 Informationspflicht",
          "Es obliegt uns, alle Peers über ihre Rechte und Pflichten sowie die geltenden Haftungsbeschränkungen zu informieren und sie in datenschutzrechtlichen sowie haftungsrelevanten Aspekten zu schulen.",
        ],
      },
      {
        Header: "9. Beendigung",
        Content: [
          "Die Nutzung von RealTalk erfordert keine Registrierung und kann daher jederzeit von euch ohne formelle Kündigung beendet werden, indem ihr einfach aufhört, den Dienst zu verwenden. Ebenso behalten wir uns das Recht vor, das Angebot von RealTalk jederzeit einzustellen oder die Verfügbarkeit zu beschränken. In einem solchen Fall werden bestehende gesetzliche und vertragliche Pflichten, insbesondere im Bereich des Datenschutzes, weiterhin von uns erfüllt.",
        ],
      },
      {
        Header: "10. Schlussbestimmungen",
        Content: [
          "Die kommerzielle Nutzung oder eine Weiterverbreitung unserer Angebote ist nicht gestattet.",
          "Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.",
          "Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam oder undurchführbar sein oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Anstelle der unwirksamen oder undurchführbaren Bestimmung gilt diejenige gesetzlich zulässige Regelung als vereinbart, die dem wirtschaftlichen Zweck der unwirksamen oder undurchführbaren Bestimmung am nächsten kommt. Das Gleiche gilt für den Fall, dass die Nutzungsbedingungen eine nicht vorhergesehene Lücke aufweisen.",
        ],
      },
    ],
  },

  // DataProtection

  DataProtection: {
    Title: "Datenschutzerklärung",
    Content: [
      {
        Header: "Vorwort",
        Content: [
          "Wir, der Deutsches Rotes Kreuz e.V. (nachfolgend: „<0>die Organisation</0>“, „<1>wir</1>“ oder „<2>uns</2>“ genannt) nehmen den Schutz Ihrer personenbezogenen Daten ernst und möchten Sie an dieser Stelle über den Datenschutz in unserer Organisation informieren.",
          "Nach der EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend: „<0>DS-GVO</0>“) bestehen Pflichten, um den Schutz personenbezogener Daten der von einer Verarbeitung betroffenen Person (wir sprechen Sie als betroffene Person nachfolgend auch mit „<1>Kunde</1>“, „<2>Nutzer</2>“, „<3>Sie</3>“, „<4>Ihnen</4>“ oder „<5>Betroffener</5>“ an) sicherzustellen. Soweit wir entweder alleine oder gemeinsam mit anderen über die Zwecke und Mittel der Datenverarbeitung entscheiden, umfasst dies vor allem die Pflicht, Sie transparent über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung zu informieren (vgl. Art. 13 und 14 DS-GVO).",
          "Mit dieser Erklärung (nachfolgend: „<0>Datenschutzhinweise</0>“) informieren wir Sie darüber, in welcher Weise Ihre personenbezogenen Daten von uns verarbeitet werden.",
        ],
      },
      {
        Header: "A. Allgemeines",
        SubHeader: "1. Begriffsbestimmungen",
        Content: [
          "Nach dem Vorbild des Art. 4 DS-GVO liegen dieser Datenschutzhinweise folgende Begriffsbestimmungen zugrunde:",
          {
            List: [
              "„Personenbezogene Daten“ (Art. 4 Nr. 1 DS-GVO) sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (sog. „Betroffener“) beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer Online-Kennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert werden kann. Die Identifizierbarkeit kann auch mittels einer Verknüpfung von derartigen Informationen oder anderem Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die Verkörperung der Informationen kommt es nicht an (auch Fotos, Video- oder Tonaufnahmen können personenbezogene Daten enthalten).",
              "„Verarbeiten“ (Art. 4 Nr. 2 DS-GVO) ist jeder Vorgang, bei dem mit personenbezogenen Daten umgegangen wird, gleich ob mit oder ohne Hilfe automatisierter (dh technikgestützter) Verfahren. Dies umfasst insbesondere das Erheben (dh die Beschaffung), das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, die Verbreitung oder sonstige Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten sowie die Änderung einer Ziel- oder Zweckbestimmung, die einer Datenverarbeitung ursprünglich zugrunde gelegt wurde.",
              "„Verantwortlicher“ (Art. 4 Nr. 7 DS-GVO) ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.",
              "„Dritter“ (Art. 4 Nr. 10 DS-GVO) ist jede natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten; dazu gehören auch andere konzernangehörige juristische Personen.",
              "„Auftragsverarbeiter“ (Art. 4 Nr. 8 DS-GVO) ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere gemäß dessen Weisungen, verarbeitet (z. B. IT-Dienstleister). Im datenschutzrechtlichen Sinne ist ein Auftragsverarbeiter insbesondere kein Dritter.",
              "„Einwilligung“ (Art. 4 Nr. 11 DS-GVO) der betroffenen Person bezeichnet jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.",
            ],
          },
        ],
      },
      {
        SubHeader:
          "2. Name und Anschrift des für die Verarbeitung Verantwortlichen",
        Content: [
          "Die für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DS-GVO sind wir:",
          {
            Lines: [
              "Deutsches Rotes Kreuz e.V.",
              "Carstennstraße 58",
              "12205 Berlin",
            ],
          },
          {
            Lines: ["Telefon: 030 / 85404 – 0", "E-Mail: drk(at)drk.de"],
          },
          "Weitere Angaben zu unserer Organisation entnehmen Sie bitte den Impressumsangaben auf unserer <0>Internetseite</0>.",
        ],
      },
      {
        SubHeader: "3. Kontaktdaten des Datenschutzbeauftragten",
        Content: [
          "Bei allen Fragen und als Ansprechpartner zum Thema Datenschutz bei uns steht Ihnen unser betrieblicher Datenschutzbeauftragter jederzeit zur Verfügung. Seine Kontaktdaten sind:",
          {
            Lines: [
              "advokIT Datenschutz",
              "Weißmann Datenschutz GmbH",
              "Schirmerstraße 30",
              "50823 Köln",
            ],
          },
          "Postanschrift:",
          {
            Lines: [
              "Riemenschneiderstraße 4",
              "55543 Bad Kreuznach",
              "Datenschutz[at]advokit.de",
            ],
          },
        ],
      },
      {
        SubHeader: "4. Rechtsgrundlagen der Datenverarbeitung",
        Content: [
          "Von Gesetzes wegen ist im Grundsatz jede Verarbeitung personenbezogener Daten verboten und nur dann erlaubt, wenn die Datenverarbeitung unter einen der folgenden Rechtfertigungstatbestände fällt:",
          {
            List: [
              "Art. 6 Abs. 1 S. 1 lit. a DS-GVO („Einwilligung“): Wenn der Betroffene freiwillig, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung zu verstehen gegeben hat, dass er mit der Verarbeitung der ihn betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden ist;",
              "Art. 6 Abs. 1 S. 1 lit. b DS-GVO: Wenn die Verarbeitung zur Erfüllung eines Vertrags, dessen Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf die Anfrage des Betroffenen erfolgen;",
              "Art. 6 Abs. 1 S. 1 lit. c DS-GVO: Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche unterliegt (z. B. eine gesetzliche Aufbewahrungspflicht);",
              "Art. 6 Abs. 1 S. 1 lit. d DS-GVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen des Betroffenen oder einer anderen natürlichen Person zu schützen;",
              "Art. 6 Abs. 1 S. 1 lit. e DS-GVO: Wenn die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder",
              "Art. 6 Abs. 1 S. 1 lit. f DS-GVO („Berechtigte Interessen“): Wenn die Verarbeitung zur Wahrung berechtigter (insbesondere rechtlicher oder wirtschaftlicher) Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte des Betroffenen überwiegen (insbesondere dann, wenn es sich dabei um einen Minderjährigen handelt). ",
            ],
          },
          "Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils die anwendbare Rechtsgrundlage an. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen.",
          "<0>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</0>",
          "Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DS-GVO bzw. Art. 9 Abs. 2 lit. a DS-GVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DS-GVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DS-GVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z.B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DS-GVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DS-GVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DS-GVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.",
        ],
      },
      {
        SubHeader: "5. Datenlöschung und Speicherdauer",
        Content: [
          "Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils an, wie lange die Daten bei uns gespeichert und wann sie gelöscht oder gesperrt werden. Soweit nachfolgend keine ausdrückliche Speicherdauer angegeben wird, werden Ihre personenbezogenen Daten gelöscht oder gesperrt, sobald der Zweck oder die Rechtsgrundlage für die Speicherung entfällt. Eine Speicherung Ihrer Daten erfolgt grundsätzlich nur auf unseren Servern im Europäischen Wirtschaftsraums (EWR), vorbehaltlich einer ggf. erfolgenden Weitergabe nach den Regelungen in A.(7) und A.(8).",
          "Eine Speicherung kann jedoch über die angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit mit Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder wenn die Speicherung durch gesetzliche Vorschriften, denen wir als Verantwortlicher unterliegen (zB § 257 HGB, § 147 AO), vorgesehen ist. Wenn die durch die gesetzlichen Vorschriften vorgeschriebene Speicherfrist abläuft, erfolgt eine Sperrung oder Löschung der personenbezogenen Daten, es sei denn, dass eine weitere Speicherung durch uns erforderlich ist und dafür eine Rechtsgrundlage besteht.",
        ],
      },
      {
        SubHeader: "6. Datensicherheit",
        Content: [
          "Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen (zB TSL-Verschlüsselung für unsere Webseite) unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen) für den Betroffenen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.",
        ],
      },
      {
        SubHeader: "7. Zusammenarbeit mit Auftragsverarbeitern",
        Content: [
          "Wir setzen zur Abwicklung unseres Geschäftsverkehrs externe in- und ausländische Dienstleister ein (z.B. für die Bereiche IT, Logistik, Telekommunikation und Marketing). Diese werden nur nach unserer Weisung tätig und wurden iSv Art. 28 DS-GVO vertraglich dazu verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.",
        ],
      },
      {
        SubHeader:
          "8. Voraussetzungen der Weitergabe von personenbezogenen Daten in Drittländer",
        Content: [
          "Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des EWR, also in Drittländern, befinden. Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung zu uns. Über die jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie nachfolgend an den dafür relevanten Stellen.",
          "Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der Angemessenheitsbeschlüsse erhalten Sie auf der Internetseite der Europäischen Kommission). In anderen Drittländern, in die ggf. personenbezogene Daten übertragen werden, herrscht aber unter Umständen wegen fehlender gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies über bindende Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen Kommission zum Schutz personenbezogener Daten, Zertifikate oder anerkannte Verhaltenskodizes. ",
        ],
      },
      {
        SubHeader:
          "9. Keine automatisiere Entscheidungsfindung (einschließlich Profiling)",
        Content: [
          "Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene Daten für ein Verfahren zur automatisierten Entscheidungsfindung (einschließlich Profiling) zu verwenden.",
        ],
      },
      {
        SubHeader:
          "10. Keine Verpflichtung zur Bereitstellung personenbezogener Daten",
        Content: [
          "Wir machen den Abschluss von Verträgen mit uns nicht davon abhängig, dass Sie uns zuvor personenbezogene Daten bereitstellen. Für Sie als Kunde besteht grundsätzlich auch keine gesetzliche oder vertragliche Verpflichtung, uns Ihre personenbezogenen Daten zur Verfügung zu stellen; es kann jedoch sein, dass wir bestimmte Angebote nur eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür erforderlichen Daten nicht bereitstellen. Sofern dies im Rahmen der nachfolgend vorgestellten, von uns angebotenen Produkte ausnahmsweise der Fall sein sollte, werden Sie gesondert darauf hingewiesen.",
        ],
      },
      {
        SubHeader:
          "11. Gesetzliche Verpflichtung zur Übermittlung bestimmter Daten",
        Content: [
          "Wir können unter Umständen einer besonderen gesetzlichen oder rechtlichen Verpflichtung unterliegen, die rechtmäßig verarbeiteten personenbezogenen Daten für Dritte, insbesondere öffentlichen Stellen, bereitzustellen (Art. 6 Abs. 1, S. 1 lit. c DS-GVO).",
        ],
      },
      {
        SubHeader: "12. Ihre Rechte",
        Content: [
          "Ihre Rechte als Betroffener bezüglich Ihrer verarbeiteten personenbezogenen Daten können Sie uns gegenüber unter den eingangs unter A.(2) angegebenen Kontaktdaten jederzeit geltend machen. Sie haben als Betroffener das Recht:",
          {
            List: [
              "gemäß Art. 15 DS-GVO Auskunft über Ihre von uns verarbeiteten Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;",
              "gemäß Art. 16 DS-GVO unverzüglich die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns gespeicherten Daten zu verlangen;",
              "gemäß Art. 17 DS-GVO die Löschung Ihrer bei uns gespeicherten Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;",
              "gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird oder die Verarbeitung unrechtmäßig ist;",
              "gemäß Art. 20 DS-GVO Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen („Datenübertragbarkeit“);",
              "gemäß Art. 21 DS-GVO Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DS-GVO), sofern die Verarbeitung aufgrund von Art. 6 Abs. 1, S. 1 lit. e oder lit. f DS-GVO erfolgt. Dies ist insbesondere der Fall, wenn die Verarbeitung nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist. Sofern es sich nicht um einen Widerspruch gegen Direktwerbung handelt, bitten wir bei Ausübung eines solchen Widerspruchs um die Darlegung der Gründe, weshalb wir Ihre Daten nicht wie von uns durchgeführt verarbeiten sollen. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen;",
              "gemäß Art. 7 Abs. 3 DS-GVO Ihre einmal erteilte Einwilligung – also Ihr freiwilliger, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung verständlich gemachter Willen, dass Sie mit der Verarbeitung der betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden sind – jederzeit uns gegenüber zu widerrufen, falls Sie eine solche erteilt haben. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und",
              "gemäß Art. 77 DS-GVO sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten in unserer Organisation zu beschweren.",
            ],
          },
          "Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im Folgenden jeweils die anwendbare Rechtsgrundlage an. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen.",
          "<0>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</0>",
          "Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DS-GVO bzw. Art. 9 Abs. 2 lit. a DS-GVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DS-GVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DS-GVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z.B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DS-GVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DS-GVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DS-GVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.",
        ],
      },
      {
        SubHeader: "13. Änderungen der Datenschutzhinweise",
        Content: [
          "Im Rahmen der Fortentwicklung des Datenschutzrechts sowie technologischer oder organisatorischer Veränderungen werden unsere Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf hin überprüft. Über Änderungen werden Sie insbesondere auf unserer Webseite unterrichtet. Diese Datenschutzhinweise haben den Stand von September 2023.",
        ],
      },
      {
        Header: "B. RealTalk",
        SubHeader: "1. Allgemeines",
        Content: [
          {
            Numbers: {
              Content: [
                "1.1. Wir betreiben die Online-Plattform „RealTalk“, die es Schüler*innen ermöglicht, anonym mit gleichaltrigen ehrenamtlichen Berater*innen (nachfolgend „Peers“) in Kontakt zu treten. ",
                "1.2. Neben der Möglichkeit des anonymen Kontakts zu Peers bietet unsere Plattform einen direkten Verweis zum Beratungsangebot der Schulsozialarbeitenden von ausgewählten Deutsches Rotes Kreuz (DRK) Kreisverbänden. ",
                "1.3. Die digitale Peer-Beratung richtet sich an Schüler*innen im Alter von 16 bis 20 Jahren und bietet Unterstützung in verschiedenen problematischen Lebenslagen wie beispielsweise Angstzuständen, schulischen Belastungen oder Mobbing. ",
                "1.4. Unsere Rolle beschränkt sich auf die Bereitstellung der technischen Plattform. Die Schulsozialarbeiter*innen sind bei verschiedenen DRK-Kreisverbänden angestellt und handeln unabhängig von uns. Für die von ihnen erbrachten Beratungsleistungen und die damit verbundene Datenverarbeitung übernehmen wir keine Verantwortung.",
                "1.5. Für die Beratungsaktivitäten der Peers sind wir jedoch als Verantwortlicher gemäß Art. 4 Nr. 7 der Datenschutz-Grundverordnung (DS-GVO) zuständig. Eine qualifizierte Beratung fördern wir durch gezielte Schulungen und Verpflichtung der Peers, sowie durch regelmäßige Supervision ihrer Beratungstätigkeit, um die Einhaltung unserer Sicherheitsstandards und Qualitätsrichtlinien sicherzustellen.",
              ],
            },
          },
        ],
      },
      {
        SubHeader: "2. Grundsätze der Verarbeitung personenbezogener Daten",
        Content: [
          {
            Numbers: {
              Content: [
                "2.1. Beratung durch die Schulsozialarbeiter*innen: Die Beratung durch Schulsozialarbeiter*innen erfolgt außerhalb der RealTalk Plattform. Für die Terminvereinbarung wird Microsoft Bookings verwendet, auf das wir lediglich verlinken. Die Datenverarbeitung durch die Schulsozialarbeiter*innen unterliegt eigenen Datenschutzbestimmungen und ist nicht Bestandteil dieser Erklärung. Auf die Datenverarbeitung durch Microsoft Bookings wird im Folgenden hingewiesen. Die Beratungen der Schulsozialarbeiter*innen können entweder vor Ort oder als Videokonferenz mittels Microsoft Teams durchgeführt werden. Zur Datenverarbeitung durch Microsoft Teams wird auf die nachfolgenden Ausführungen verwiesen. Die Datenschutzbestimmungen der DRK-Kreisverbände gehen unseren soweit vor. ",
                "2.2. Beratung durch die Peers: Der Dienst RealTalk ist über SMS und WhatsApp erreichbar. Über diese Kanäle können Schüler*innen mit den von uns verantworteten Peers in Kontakt treten. Im Rahmen der Diensterbringung erheben und verarbeiten wir die von den Schüler*innen übermittelten personenbezogenen Daten, insbesondere solche, die den Gemüts- und Gesundheitszustand sowie die Lebensumstände betreffen.",
                "2.3. Nach Kontaktaufnahme über unsere Plattformen erhalten Schüler*innen eine Nachricht, die unter anderem auf unsere Datenschutzerklärung und Nutzungsbedingungen hinweist. Erst nach Bestätigung der Kenntnisnahme und Erteilung der erforderlichen datenschutzrechtlichen Einwilligungen können Schüler*innen den Dienst RealTalk gemäß den Bedingungen der Nutzungsbedingungen in Anspruch nehmen.",
              ],
            },
          },
        ],
      },
      {
        SubHeader:
          "3.	Datenverarbeitungsvorgänge im Kontext der Peer-Kommunikation",
        Content: [
          "3.1.	Kontaktaufnahme",
          "Bei der ersten Kontaktaufnahme mit einem Peer werden folgende Daten der Jugendlichen automatisch erfasst:",
          {
            List: [
              "Mobilfunknummer",
              "Datum und Uhrzeit der Kontaktaufnahme",
              "Inhalt der Anfrage",
            ],
          },
          "Diese Daten werden auf Basis von Art. 6 Abs. 1 lit. b) DSGVO erhoben, um die Kommunikation mit dem Peer zu ermöglichen und den Dienst RealTalk zur Verfügung zu stellen. Sie werden für eine festgelegte Dauer während der aktiven Kommunikationsphase gespeichert.",
          "3.2. Datenverarbeitung während der Peer-Kommunikation",
          "3.2.1. Allgemeine Datenverarbeitung",
          "Während der aktiven Kommunikationsphase mit dem Peer erfassen und verarbeiten wir ergänzende Daten, die die Jugendlichen uns freiwillig übermitteln. Dies können auch sensible personenbezogene Daten sein, etwa Gesundheitsinformationen. Zu den Daten gehören:",
          {
            List: [
              "direkte Kommunikationsinhalte (z.B. Text- oder Sprachnachrichten)",
              "ggf. übermittelte Dokumente oder Multimedia-Inhalte.",
            ],
          },
          "Diese Datenverarbeitung ist ebenfalls gemäß Art. 6 Abs. 1 lit. b) DSGVO erforderlich für die Vertragserfüllung.",
          "Daneben werden Metadaten, etwa zum Empfang und Öffnen der Nachrichten verarbeitet. Zu den verarbeiteten Metadaten gehören Zeitpunkt des Empfangs und des Öffnens der Nachricht. Diese Daten werden primär zur Durchführung der Beratungsleistung verwendet.",
          "3.2.2.	Sensible personenbezogene Daten",
          "Im Fall von sensiblen personenbezogenen Daten, wie etwa Gesundheitsinformationen, erfolgt die Verarbeitung auf Grundlage einer expliziten Einwilligung der Jugendlichen gemäß Art. 9 Abs. 2 lit. a) DSGVO. Die Einwilligung wird im Rahmen der Annahme der Nutzungsbedingungen und der Datenschutzerklärung vor der Nutzung des Dienstes eingeholt. Ein Widerruf der Einwilligung ist möglich, hat jedoch zur Folge, dass die Beratungsleistung nicht weiter erbracht werden kann.",
          "3.2.3.	Qualitätssicherung",
          "Für Zwecke der Qualitätssicherung und Dokumentation können diese Daten erfasst und für Supervisionszwecke verwendet werden. Diese Datenverarbeitungen sind erforderlich für die ordnungsgemäße Vertragserfüllung gemäß Art. 6 Abs. 1 lit. b) DSGVO. Die Qualitätssicherung und Supervision sind integraler Bestandteil unserer Dienstleistung, um eine hohe Beratungsqualität sicherzustellen und gesetzlichen sowie vertraglichen Verpflichtungen nachzukommen.",
          "3.3.	Analysezwecke und Qualitätsverbesserung des Dienstes",
          {
            Numbers: {
              Content: [
                "3.3.1. Zur kontinuierlichen Verbesserung der Leistungen und Funktionen von RealTalk, sowie zur Prävention und Behebung von Missbrauch und Fehlfunktionen, können wir das Datum und die Uhrzeit verarbeiten. Wir haben ein berechtigtes Interesse daran, die Integrität, Sicherheit und Funktionalität unseres Angebots zu gewährleisten. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f) DSGVO. Sensible personenbezogene Daten werden hierbei nur in pseudonymisierter Form oder im Rahmen einer expliziten Einwilligung gemäß Art. 9 Abs. 2 lit. a) DSGVO verwendet.",
                "3.3.2. Wir erstellen aus pseudonymisierten Informationen aggregierte Gruppenanalysen. Diese ermöglichen keine Rückschlüsse auf individuelle Nutzer und dienen der Erstellung statistischer Informationen und Auswertungen. Diese Informationen können auch verwendet werden, um die Nutzung des Dienstes gegenüber Dritten, die die Kosten für die Bereitstellung des Dienstes übernehmen – etwa Partnerunternehmen oder fördernden Institutionen – zu belegen. Ein Rückschluss auf individuelle Nutzer ist diesen Dritten zu keinem Zeitpunkt möglich.",
              ],
            },
          },
          "3.4.	Auswertung durch Forschungseinrichtungen",
          "Für Zwecke der wissenschaftlichen Forschung und Evaluierung dürfen die in Abschnitt 3.1 und 3.2 erfassten Daten, einschließlich Chat-Protokollen, an akkreditierte Evaluationsinstitute und Forschungseinrichtungen weitergegeben werden. Die Daten werden hierbei in einer Form bereitgestellt, die die Identifizierung der betroffenen Personen nicht mehr ermöglicht, es sei denn, dies ist für die Forschungszwecke erforderlich. Diese Datenverarbeitung erfolgt in Übereinstimmung mit Art. 89 Abs. 1 DSGVO, der besondere Schutzmaßnahmen für die Verarbeitung personenbezogener Daten für wissenschaftliche Forschungszwecke vorsieht, sowie unter Beachtung des § 27 BDSG, der die Verarbeitung für Forschung und Statistik regelt. Eine Weitergabe an Dritte, die nicht den in diesem Abschnitt definierten Forschungszwecken dienen, findet nicht statt",
        ],
      },
      {
        SubHeader: "4. Ausnahmen zur Datenweitergabe",
        Content: [
          "In bestimmten, klar definierten Ausnahmefällen dürfen bzw. müssen personenbezogene Daten eingesehen oder weitergegeben werden. Diese Ausnahmen betreffen sowohl den Schutz der Plattform als auch den Schutz der Ratsuchenden und der Allgemeinheit. RealTalk hat dafür festgelegte Abläufe definiert, nach denen im Bedarfsfall zu handeln ist.",
          "4.1. Zum Schutz der Ratsuchenden",
          "Das Strafgesetzbuch (StGB) regelt den Schutz der Privatgeheimnisse der Ratsuchenden in zweierlei Hinsicht: Berufliche Schweigepflicht: Alle Mitarbeitenden von RealTalk unterliegen der beruflichen Schweigepflicht nach §203 StGB. Eine unbefugte Offenbarung von Geheimnissen kann mit einer Freiheitsstrafe bis zu einem Jahr, bei bestimmten Beweggründen sogar bis zu zwei Jahren, bestraft werden. Gefahr für Leben und Gesundheit: Wenn das Leben eines/einer Ratsuchenden durch Suizid bedroht ist, verpflichtet das StGB zur Offenbarung der Daten. Bei einer deutlich geäußerten Ankündigung einer Suizidabsicht gebietet §323c StGB (Unterlassene Hilfeleistung) eine Durchbrechung der Schweigepflicht, um die Gefahr abzuwenden. RealTalk hat dazu detaillierte Anweisungen für das Beraterteam erarbeitet.",
          "4.2. Zum Schutz der Allgemeinheit und des Lebens Dritter",
          "Schwere Straftaten: Ankündigungen einer schweren Straftat müssen nach §138 StGB angezeigt werden. Wer glaubhaft von konkreten Plänen oder bereits begonnenen Taten bezüglich Mord, Geiselnahme, Terrorangriff, Amoklauf o.ä. erfährt, muss die Ermittlungsbehörden informieren. Rechtfertigender Notstand: Bei Ankündigungen weniger schwerer Taten (Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut) erlaubt §34 StGB die Durchbrechung der Schweigepflicht unter bestimmten Bedingungen. Diese Durchbrechung ist nur erlaubt, wenn: oDie Gefahr konkret und gegenwärtig ist. oDie Durchbrechung der Schweigepflicht geeignet und angemessen ist, um die Gefahr abzuwenden. oKein milderes Mittel als die Verletzung der Schweigepflicht zur Verfügung steht, um die Gefahr abzuwenden. oEine Abwägung ergibt, dass das Schutzinteresse gegenüber der Schweigepflicht wesentlich überwiegt.",
          "4.3. Kindesschutz",
          "Kindeswohlgefährdung muss (§138 StGB) bzw. darf (§34 StGB) nur angezeigt werden, wenn sie unter einen der beiden Paragrafen fällt. Keine Anzeige darf erstattet werden, wenn die Tat in der Vergangenheit lag und nicht mehr verhindert werden kann.",
        ],
      },
      {
        SubHeader: "5.Verarbeitung von Daten Minderjähriger",
        Content: [
          "RealTalk richtet sind an Personen ab 16 Jahren. Unsere Dienste dürfen auch ohne Zustimmung eines Erziehungsberechtigten in Anspruch genommen werden, da es sich um Präventions- oder Beratungsdienste handelt, die auch direkt an Minderjährige gerichtet sind. Diese Ausnahme stützt sich auf Erwägungsgrund 38 der DS-GVO, der besagt, dass die Einwilligung des Trägers der elterlichen Verantwortung in solchen Fällen nicht erforderlich sein sollte. Dabei legen wir höchste Sorgfalt hinsichtlich der Art der Daten und der Verarbeitungszwecke an den Tag, um sicherzustellen, dass diese Verarbeitung dem Wohl des Minderjährigen dient.",
        ],
      },
      {
        SubHeader: "6. Eingesetzte Anwendungen",
        Content: [
          "6.1.	Amazon Web Services (AWS)",
          "Wir hosten die Anwendung RealTalk bei AWS. Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend AWS).",
          "Wenn Sie unsere Anwendung nutzen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt. Details finden Sie hier: https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.",
          "Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS: https://aws.amazon.com/de/privacy/?nc1=f_pr.",
          "Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.",
          "Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten.  Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active",
          "Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active",
          "Auftragsverarbeitung",
          "Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.",
          "6.2. Microsoft Bookings",
          "Auf den Webseiten der DRK-Kreisverbände haben Sie die Möglichkeit, Termine mit den Schulsozialarbeitenden zu vereinbaren. Für die Terminbuchung wird Microsoft Bookings verwendet. Anbieter ist die Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, Irland, https://learn.microsoft.com/de-de/microsoft-365/bookings/?view=o365-worldwide.",
          "Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und den Wunschtermin in die dafür vorgesehene Maske ein. Die Terminbuchung kann gegenüber den Schulsozialarbeitenden bei Bedarf anonym erfolgen. Um sicherzustellen, dass die Jugendlichen den/die richtigen Schulsozialarbeitenden seiner Schule findet, ist eine E-Mail-Adresse erforderlich. Die eingegebenen Daten werden für die Planung, Durchführung und ggf. für die Nachbereitung des Termins verwendet. Die Termindaten werden auf den Servern von Microsoft Bookings gespeichert, dessen Datenschutzerklärung Sie hier einsehen können: https://privacy.microsoft.com/de-de/privacystatement.",
          "Die von Ihnen eingegebenen Daten verbleiben dort, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.",
          "Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst unkomplizierten Terminvereinbarung mit Interessenten und Kunden. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. für Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.",
          "Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://learn.microsoft.com/de-de/compliance/regulatory/offering-eu-model-clauses.",
          "Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active",
          "6.3.	Kommunikation per SMS",
          "Sollte der Kontakt zwischen den Jugendlichen und dem Dienst über SMS hergestellt werden, wird darauf hingewiesen, dass die Datenübertragung in der Regel unverschlüsselt erfolgt und die Verantwortlichkeit für die Datensicherheit bis zum Eingang der SMS-Nachricht beim jeweiligen Telekommunikationsanbieter der Jugendlichen liegt. SMS sind anfällig für unbefugten Zugriff oder Manipulation auf dem Übertragungsweg. Für die Sicherheit und Integrität der Daten während der Übertragung übernehmen wir daher keine Haftung. Die Jugendlichen werden ausdrücklich darauf hingewiesen, dass sie sensible oder besondere Kategorien von personenbezogenen Daten nicht über SMS kommunizieren sollten",
          "Bei der Verarbeitung der über SMS erhaltenen Daten gelten im Übrigen die in Abschnitt 3.1 und 3.2 dieser Datenschutzerklärung festgelegten Grundsätze und Verantwortlichkeiten.",
          "6.4.	Kommunikation via WhatsApp",
          "Für die Kommunikation nutzen wir unter anderem den Instant-Messaging-Dienst WhatsApp. Anbieter ist die WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Die technische Integration von WhatsApp in unsere Kommunikationssysteme erfolgt durch die Twilio Germany GmbH, Unter den Linden 10, 10117 Berlin.",
          "Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige Dritte Zugriff auf die Kommunikationsinhalte erlangen können. WhatsApp erhält jedoch Zugriff auf Metadaten, die im Zuge des Kommunikationsvorgangs entstehen (z. B. Absender, Empfänger und Zeitpunkt). Wir weisen ferner darauf hin, dass WhatsApp nach eigener Aussage, personenbezogene Daten seiner Nutzer mit seiner in den USA ansässigen Konzernmutter Meta teilt. Weitere Details zur Datenverarbeitung finden Sie in der Datenschutzrichtlinie von WhatsApp unter: https://www.whatsapp.com/legal/#privacy-policy.",
          "Der Einsatz von WhatsApp erfolgt auf Grundlage unseres berechtigten Interesses an einer möglichst schnellen und effektiven Kommunikation mit Kunden, Interessenten und sonstigen Geschäfts- und Vertragspartnern (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Datenverarbeitung ausschließlich auf Grundlage der Einwilligung; diese ist jederzeit mit Wirkung für die Zukunft widerrufbar.",
          "Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.",
          "Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt00000011sfnAAA&status=Active",
          "Wir nutzen WhatsApp in der Variante „WhatsApp Business“.",
          "Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://www.whatsapp.com/legal/business-data-transfer-addendum.",
          "Wir haben unsere WhatsApp-Accounts so eingestellt, dass es keinen automatischen Datenabgleich mit dem Adressbuch auf den im Einsatz befindlichen Smartphones macht.",
          "Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.",
          "6.5.	Audio- und Videokonferenzen",
          "Für die Kommunikation setzen die DRK-Kreisverbände unter anderen Online-Konferenz-Tools ein. Die im Einzelnen genutzten Tools sind unten aufgelistet. Wenn Sie mit den Schulsozialarbeiter*innen per Video- oder Audiokonferenz via Internet kommunizieren, werden Ihre personenbezogenen Daten von deren Trägern und dem Anbieter des jeweiligen Konferenz-Tools erfasst und verarbeitet.",
          "Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz, Anzahl der Teilnehmer und sonstige „Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang (Metadaten).",
          "Des Weiteren verarbeitet der Anbieter des Tools alle technischen Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version, Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die Art der Verbindung.",
          "Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und andere Informationen, die während der Nutzung des Dienstes geteilt werden.",
          "Bitte beachten Sie, dass wir keinen Einfluss und auch die Träger keinen vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der verwendeten Tools haben. Die Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung durch die Konferenztools entnehmen Sie den Datenschutzerklärungen der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt haben.",
          "Zweck und Rechtsgrundlagen",
          "Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden Vertragspartnern zu kommunizieren oder bestimmte Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen Vereinfachung und Beschleunigung der Kommunikation (berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf Grundlage dieser Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die Zukunft widerrufbar.",
          "Speicherdauer",
          "Die unmittelbar über die Video- und Konferenz-Tools erfassten Daten werden von den Systemen gelöscht, sobald Sie den zuständigen DRK-Kresiverband zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben unberührt.",
          "Auf die Speicherdauer Ihrer Daten, die von den Betreibern der Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der Konferenz-Tools.",
          "Eingesetzte Konferenz-Tools",
          "Die DRK-Kreisverbände nutzen folgende Konferenz-Tools:",
          "Microsoft Teams",
          "Anbieter ist die Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, Irland. Details zur Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Microsoft Teams: https://privacy.microsoft.com/de-de/privacystatement.",
          "Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active",
          "Auftragsverarbeitung",
          "Die DRK-Kreisverbände haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.",
        ],
      },
    ],
  },
  DataProtectionTitle: "Datenschutzerklärung",
  DataProtectionPrefaceTitle: "Vorwort",
  DataProtectionPrefacePt1:
    'Wir, der Deutsches Rotes Kreuz e.V. (DRK-Generalsekretariat) nachfolgend "die Organisation", "wir" oder "uns") nehmmen den Schutz Ihrer personenbezogenen Daten ernst und möchten Sie an dieser Stelle über den Datenschutz in unserer Organisation informieren.',
  DataProtectionPrefacePt2:
    "Nach der EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016",
  DataProtectionGeneralTitle: "A. Allgemeines",

  // Accessability

  Accessability: {
    Title: "Barrierefreiheit dieser Internetseite",
    SubTitle:
      "Diese Erklärung zur Barrierefreiheit gilt für die Internetseite RealTalk.help",
    Content: {
      Sections: [
        {
          Headline: "Barrierefreiheit dieser Internetseite",
          Content:
            "Das Deutsche Rote Kreuz e.V. ist bemüht, die Internetseite www.RealTalk.help barrierefrei zugänglich zu machen. Rechtsgrundlage sind das Behindertengleichstellungsgesetz (BGG) und die Barrierefreie Informationstechnik-Verordnung (BITV 2.0) in ihrer jeweils gültigen Fassung.",
        },
        {
          Headline: "Erstellung dieser Erklärung zur Barrierefreiheit",
          Content:
            "Die Aussagen in der Erklärung bezüglich der Vereinbarkeit mit den Barrierefreiheitsanforderungen beruhen auf der Auswertung der Checklisten des Landeskompetenzzentrums für barrierefreie IT des Bundeslandes Hessen.",
        },
      ],
      Feedback: {
        Headline: "Feedback und Kontakt",
        Content:
          "Etwaige Mängel in Bezug auf die Einhaltung der Barrierefreiheitsanforderungen können Sie uns über unser <0>Kontakt-Formular</0> mitteilen.<br /><br />Zuständig für die barrierefreie Zugänglichkeit ist:<br />Deutsches Rotes Kreuz e.V.<br />Carstennstraße  58<br />10249 Berlin<br />E-Mail: realtalk@drk.de<br />",
      },
    },
  },

  // ShoolSelelectionGDPRModal

  ShoolSelelectionGDPRModalTitle: "Hinweis: Du verlässt jetzt RealTalk",
  ShoolSelelectionGDPRModalConditionText:
    "Um einen Termin über Microsoft Bookings zu vereinbaren, bitten wir dich, den <1>{{link1}}</1> von RealTalk zuzustimmen und die <2>{{link2}}</2> zur Kenntnis zu nehmen.",
  ShoolSelelectionGDPRModalConditonAgreementText:
    "Ich habe die Nutzungsbedingungen und Datenschutzbestimmungen von RealTalk gelesen und stimme diesen Bedingungen zu.",
  ShoolSelelectionGDPRModaSubmitButtonText: "Weiter zur Terminbuchung",
  ShoolSelelectionGDPRModalChancelButtonText: "Abbrechen",
  ShoolSelelectionGDPRModalValidationMsg: "Zustimmung erforderlich",

  // ChatAdviceGDPRModal
  ChatAdviceGDPRModalTitle: "Hinweis: Du verlässt jetzt RealTalk",
  ChatAdviceGDPRModalConditionText:
    "Um über WhatsApp oder SMS zu kommunizieren, bitten wir dich, den <1>{{link1}}</1> von RealTalk zuzustimmen und die <2>{{link2}}</2> zur Kenntnis zu nehmen. ",
  ChatAdviceGDPRModalAgreemnetText:
    "Ich habe die Nutzungsbedingungen und Datenschutzbestimmungen von RealTalk gelesen und stimme diesen Bedingungen zu.",
  ChatAdviceGDPRModalWhatsappButtonText: "Weiter zu WhatsApp",
  ChatAdviceGDPRModalSMSButtonText: "Weiter zu SMS",
  ChatAdviceGDPRModalValidationMsg: "Zustimmung erforderlich",
  ChatAdviceGDPRModalChancelButtonText: "Abbrechen",
  // emplpoyee section - should be rendered dymically

  // Footer
  SupportedBy: "Unterstützt durch:",
};

export default base;
