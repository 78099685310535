import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  // MenuItem,
  Radio,
  RadioGroup,
  // Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ContactFormData,
  // ContactFormThema,
  ContactFormUserType,
} from "../../models/contactFormData";
import contactService from "../../services/contactService";
import { useNavigate } from "react-router-dom";
import {
  // contactFormThemas,
  contactFormUserTypes,
} from "../../i18n/locales/de/itemsFields";

const stylesComponent = {
  sectionWrapper: {
    margin: "0 20px",
    padding: "0 0px",
  },
  sectionHeader: {
    fontSize: "36px",
    fontWeight: "700",
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
  },
  radioButtonsWrapper: {
    textAlign: "left",
  },
  radioLabel: {
    color: "#333",
    fontWeight: "700",
    fontSize: "20px",
  },
  select: {
    width: { xs: "100%", md: "480px" },
    backgroundColor: "#fff",
    margin: "10px 0",
    color: "#2276D0",
    fontWeight: "700",
    textAlign: "left",
    "& svg": {
      color: "#B4B4B4",
    },
  },
  inputWrapper: {
    textAlign: "left",
  },
  input: {
    width: { xs: "100%", md: "480px" },
    backgroundColor: "#fff",
    margin: "10px 0",
    color: "#2276D0",
    fontWeight: "700",
    textAlign: "left",
  },
  themeSelectLabel: {
    fontWeight: "700",
    color: "#333",
    fontSize: "20px",
  },
  inputLabel: {
    fontWeight: "700",
    color: "#333",
    fontSize: "20px",
  },
  messageBox: {
    width: { xs: "100%", md: "480px" },
    height: "180px",
    backgroundColor: "#fff",
    margin: "10px 0",
    color: "#2276D0",
    fontWeight: "700",
    textAlign: "left",
  },
};

const ContactFormsSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const navigate = useNavigate();
  const initialContactData = {
    userType: "",
    subject: t("ContactForm.DefaultSubject"),
    userName: "",
    organization: "",
    content: "",
    userMail: "",
  };
  const [contactFormData, setContactFormData] =
    useState<ContactFormData>(initialContactData);

  const handleSubmitForm = () => {
    handleGoogleAnalyticsEvent();
    contactService
      .sendContactForm(contactFormData)
      .then((res: any) => {
        console.log(res.data.message);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => {
        navigate("/");
      });
  };

  const handleGoogleAnalyticsEvent = () => {
    if (typeof (window as any).gtag !== "undefined") {
      (window as any).gtag("event", "submit_contact_form_click", {
        event_category: "User Interaction",
        event_label: "submitContactFormButton",
        value: 1,
      });
    } else {
      console.warn("gtag function is not defined");
    }
  };

  const handleValidateForm = ["userType", "userMail", "content"].every(
    (value) => (contactFormData as any)[value].length !== 0
  );

  const isValidEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(contactFormData.userMail);
  };

  const isFormValid = handleValidateForm && isValidEmail();

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"} m={{ md: "80px 0 80px 0" }}>
          <Box mb={6} pt={6}>
            <Box sx={stylesComponent.radioButtonsWrapper}>
              <FormLabel
                id="radio-buttons-group-label"
                sx={stylesComponent.radioLabel}
              >
                {t("ContactFormLabel")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={contactFormData?.userType}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    userType: e.target.value,
                  })
                }
              >
                {contactFormUserTypes.map((userType: ContactFormUserType) => (
                  <FormControlLabel
                    key={userType.value}
                    value={userType.value}
                    control={<Radio />}
                    label={userType.label}
                  />
                ))}
              </RadioGroup>
            </Box>

            {/* <Box mt={4} mb={2} textAlign={"left"}>
              <InputLabel
                id="select-theme-label"
                sx={stylesComponent.inputLabel}
              >
                {t("ThemaInputLabel")}
              </InputLabel>
              <Select
                labelId="select-theme-label"
                id="select-theme"
                value={contactFormData.subject}
                sx={stylesComponent.select}
                IconComponent={ExpandMoreIcon}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    subject: e.target.value,
                  })
                }
              >
                {contactFormThemas.map((thema: ContactFormThema) => (
                  <MenuItem key={thema.value} value={thema.value}>
                    {thema.label}
                  </MenuItem>
                ))}
              </Select>
            </Box> */}

            <Box mt={2} mb={2} sx={stylesComponent.inputWrapper}>
              <InputLabel id="input-name" sx={stylesComponent.inputLabel}>
                {t("YourNameInputLabel")}
              </InputLabel>
              <TextField
                id="input-name"
                label="Name eingeben"
                variant="outlined"
                sx={stylesComponent.input}
                InputLabelProps={{
                  sx: {
                    fontWeight: "700",
                  },
                }}
                value={contactFormData.userName}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    userName: e.target.value,
                  })
                }
              />
            </Box>

            <Box mt={2} mb={2} sx={stylesComponent.inputWrapper}>
              <InputLabel id="input-school" sx={stylesComponent.inputLabel}>
                {t("OrganizationIputLabel")}
              </InputLabel>
              <TextField
                id="input-school"
                label="Name eingeben"
                variant="outlined"
                sx={stylesComponent.input}
                InputLabelProps={{
                  sx: {
                    fontWeight: "700",
                  },
                }}
                value={contactFormData.organization}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    organization: e.target.value,
                  })
                }
              />
            </Box>

            <Box pb={4} mb={2} sx={stylesComponent.inputWrapper}>
              <InputLabel id="text-area-input" sx={stylesComponent.inputLabel}>
                {t("YoursMessageInputLabel")}
              </InputLabel>
              <TextField
                id="text-area-input"
                variant="outlined"
                multiline
                sx={stylesComponent.messageBox}
                rows={8}
                value={contactFormData.content}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    content: e.target.value,
                  })
                }
              />
            </Box>

            <Box pt={2} mb={2} sx={stylesComponent.inputWrapper}>
              <InputLabel id="input-email" sx={stylesComponent.inputLabel}>
                {t("YoursEmailInputLabel")}
              </InputLabel>
              <TextField
                id="input-email"
                variant="outlined"
                sx={stylesComponent.input}
                InputLabelProps={{
                  sx: {
                    fontWeight: "700",
                  },
                }}
                type="mail"
                value={contactFormData.userMail}
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    userMail: e.target.value,
                  })
                }
              />
            </Box>

            <Box mt={5} mb={2}>
              {isFormValid && isValidEmail() ? (
                <Button
                  sx={{
                    color: "#fff !important",
                    width: "366px",
                    height: "56px",
                    borderRadius: "8px",
                    textTransform: "inherit",
                    fontWeight: "700",
                    backgroundColor: "#E60005",
                  }}
                  onClick={() => handleSubmitForm()}
                >
                  {t("SendMessageButtonText")}
                </Button>
              ) : (
                <>
                  <Typography
                    color={"#E60005"}
                    width={{ xs: "100%", md: "480px" }}
                    mb={2}
                  >
                    {t("DisabledContactButtonMSG")}
                  </Typography>
                  <Button
                    sx={{
                      color: "#fff !important",
                      width: { xs: "100%", md: "480px" },
                      height: "56px",
                      borderRadius: "8px",
                      textTransform: "inherit",
                      fontWeight: "700",
                      backgroundColor: "#B4B4B4",
                    }}
                  >
                    {t("SendMessageButtonText")}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContactFormsSection;
