import React from "react";
import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  contactBtn: {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "700",
    borderRadius: "64px",
    float: "left",
    margin: "20px",
  },
};

const ContactUs = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  const handleGoogleAnalyticsEvent = () => {
    if (typeof (window as any).gtag !== "undefined") {
      (window as any).gtag("event", "redirect_to_contact_us_click", {
        event_category: "User Interaction",
        event_label: "redirectToContactUsButton",
        value: 1,
      });
    } else {
      console.warn("gtag function is not defined");
    }
  };

  return (
    <Button
      sx={stylesComponent.contactBtn}
      style={{ width: `${isMobile ? "calc(100% - 40px)" : "233px"}` }}
      href={"/kontakt"}
      onClick={() => handleGoogleAnalyticsEvent()}
    >
      {t("ParticipateExpertsContactUs.Button")}
    </Button>
  );
};

export default ContactUs;
