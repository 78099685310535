import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useSchools } from "../../context/SchoolContext";
import { OpeningHours } from "../../models/openingHours";
import moment from "moment";
import Calendar from "../../assets/calendar.svg";

const stylesComponent = {
  openHourBanner: {
    width: "100%",
    padding: "16px 16px",
    backgroundColor: "#E60005",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
  },
  calendarImg: {
    marginRight: "16px",
  },
  sectionWrapper: {
    backgroundColor: "#EBF5FF",
    paddingTop: "48px",
    padding: "48px 10px",
  },
  terms: {
    fontSize: "15px",
    fontWeight: "700",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
  termClose: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#E60005",
  },
  startTermInfo: {
    color: "#E60005",
    fontSize: "15px",
    fontWeight: "700",
    "& span": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
};

type Props = {
  isChatOpen: boolean;
  currentTime: any;
  openingHours: OpeningHours | undefined;
};

const HeaderSection = ({
  isChatOpen,
  currentTime,
  openingHours,
}: Props): React.ReactElement => {
  const { t } = useTranslation("base");
  const { setOpenChatAdviceGDPRModal, setSelectChatAdviceGDRPModalWhatsApp } =
    useSchools();

  const handleSelectWhatsappChat = () => {
    setOpenChatAdviceGDPRModal(true);
    setSelectChatAdviceGDRPModalWhatsApp(true);
  };

  const openingHoursList =
    openingHours &&
    Object.entries(openingHours).filter(
      ([_, details]) => details.state === "open"
    );

  const getOpeningHours = (): string => {
    const foundHours =
      openingHours &&
      Object.entries(openingHours).find(([a, _]) => a === "Tuesday");

    if (foundHours) {
      return `${moment(foundHours[1].open, "HH").format("HH")} bis ${moment(
        foundHours[1].close,
        "HH"
      ).format("HH")} Uhr`;
    }
    return `${"  "} bis ${"  "} Uhr`;
  };

  // const handleSelectSMSChat = () => {
  //   setOpenChatAdviceGDPRModal(true);
  //   setSelectChatAdviceGDRPModalWhatsApp(false);
  // };

  return (
    <>
      {!isChatOpen && (
        <Box sx={stylesComponent.openHourBanner}>
          <Box sx={stylesComponent.calendarImg}>
            <img src={Calendar} alt="Calendar" height={32} width={32} />
          </Box>
          <Typography>
            <span style={{ fontWeight: "700" }}>{t("OpenHoursBanner.0")}</span>
            &nbsp;
            {t("OpenHoursBanner.1")}&nbsp;
            {getOpeningHours()}
          </Typography>
        </Box>
      )}
      <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Box maxWidth={"600px"}>
            <Box mt={2}>
              <Typography variant="h1">{t("Header")}</Typography>
            </Box>
            <Box mt={2}>
              <Typography>{t("SubHeader")}</Typography>
            </Box>
            <Box mt={4}>
              {isChatOpen ? (
                <Button
                  sx={{ margin: 0 }}
                  onClick={() => handleSelectWhatsappChat()}
                >
                  {t("Buttons.0")}
                  <OpenInNewIcon sx={{ ml: 2 }} />
                </Button>
              ) : (
                <Button
                  disabled
                  sx={{ margin: 0, maxWidth: "350px", width: "100%" }}
                  onClick={() => handleSelectWhatsappChat()}
                >
                  {t("Buttons.0")}
                  <OpenInNewIcon sx={{ ml: 2 }} />
                </Button>
              )}
            </Box>
            {/* SMS button --------> */}
            {/* <Box mt={2}>
            <Button
              disabled={isChatOpen}
              sx={{ margin: 0 }}
              onClick={() => handleSelectSMSChat()}
            >
              {t("Buttons.1")}
              <OpenInNewIcon sx={{ ml: 2 }} />
            </Button>
          </Box> */}
            <Box mt={3}>
              {/* remove this message when set chat active after january 2024 */}

              {!isChatOpen && currentTime && (
                <Typography mb={2} sx={stylesComponent.termClose}>
                  {t("Terms.0")}
                </Typography>
              )}

              <Typography sx={stylesComponent.terms}>
                {!isChatOpen && currentTime && (
                  <>
                    {openingHoursList?.map(([_, details], index) => (
                      <Box key={index}>
                        <strong>{details.germanName}:&nbsp;</strong>
                        <span>
                          {moment(details.open, "HH").format("HH")} -{" "}
                          {moment(details.close, "HH").format("HH")} Uhr
                        </span>
                      </Box>
                    ))}
                  </>
                )}
              </Typography>

              {/* <Typography sx={stylesComponent.terms}>
              {t("Terms.3")} <span> {t("Terms.4")}</span>
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default HeaderSection;
